<template>
  <b-container class="mt-4" fluid>

    <b-row>
      <b-col cols="4">
        <h3>Revision Requests</h3>
      </b-col>
      <b-col>
        <b-button variant="primary" class="float-right" v-b-toggle.filters-collapsible>
          <feather type="filter"></feather>&nbsp;{{ isFiltersVisible ? 'Hide' : 'Show' }} filters
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-collapse id="filters-collapsible" class="mt-2" v-model="isFiltersVisible">
          <b-form @submit="submitFilters" @reset="resetFilters">
            <b-card>
              <template #header>
                <h4 class="mb-0">Filters</h4>
              </template>

              <b-row>
                <b-col>
                  <b-form-group label="Entity ID" label-for="entity-id-filter">
                    <b-form-input
                      id="entity-id-filter"
                      type="number"
                      min="1"
                      placeholder="Type the numeric ID"
                      v-model="filtersForm.entityId"/>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group label="Type" label-for="entity-type-filter">
                    <b-form-select
                      id="entity-type-filter"
                      v-model="filtersForm.entityType"
                      :options="entityTypesFilterOptions"/>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group label="Status" label-for="status-filter">
                    <multiselect
                      id="status-filter"
                      placeholder="Select one or more status"
                      v-model="filtersForm.status"
                      :options="statusFilterOptions"
                      :multiple="true"></multiselect>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group label="Requested by" label-for="created-by-filter">
                    <b-form-select
                      id="created-by-filter"
                      v-model="filtersForm.requestedBy"
                      :options="userFilterOptions"/>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group label="Updated by" label-for="updated-by-filter">
                    <b-form-select
                      id="updated-by-filter"
                      v-model="filtersForm.updatedBy"
                      :options="userFilterOptions"/>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group label="Decided by" label-for="approved-by-filter">
                    <b-form-select
                      id="approved-by-filter"
                      v-model="filtersForm.decidedBy"
                      :options="userFilterOptions"/>
                  </b-form-group>
                </b-col>
              </b-row>

              <template #footer>
                <div class="float-right">
                  <b-button variant="outline-danger" type="reset" class="ml-3">Reset</b-button>
                  <b-button variant="primary" type="submit" class="ml-3">Apply</b-button>
                </div>
              </template>
            </b-card>
          </b-form>
        </b-collapse>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col>
        <b-table
          id="rr-table"
          :fields="fields"
          :items="items"
          :busy="loading"
          bordered
          striped
          show-empty
          hover>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #empty>
            <div class="text-center my-2">
              <strong>No revision requests to show</strong>
            </div>
          </template>

          <template #cell(entity_type)="data">
            <code>{{ data.item.entity_type }}</code>
          </template>

          <template #cell(status)="data">
            <code>{{ data.item.status }}</code>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="d-flex justify-content-between">
        <div>
          <p>
            Number of rows:
            <b-spinner v-if="loading" small></b-spinner>
            <strong v-else>{{ pagination.totalRows }}</strong>
          </p>
        </div>
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          :disabled="loading"
          @change="updatePage"
          aria-controls="rr-table"/>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';

export default {
  name: 'ListRevisionRequests',
  components: { Multiselect },
  data() {
    const loadingFilterOptions = [ { text: 'Loading', value: null, disabled: true } ];
    return {
      loading: true,
      isFiltersVisible: false,
      fields: [
        { key: 'id', label: 'Req. ID' },
        { key: 'entity_type', label: 'Type' },
        { key: 'entity_id', label: 'Entity ID' },
        { key: 'created_at', label: 'Created At' },
        { key: 'requested_by', label: 'Requested By' },
        { key: 'updated_at', label: 'Updated At' },
        { key: 'updated_by', label: 'Updated By' },
        { key: 'status', label: 'Status' },
        { key: 'decided_by', label: 'Decided By' },
      ],
      entityTypesFilterOptions: loadingFilterOptions,
      statusFilterOptions: loadingFilterOptions,
      userFilterOptions: loadingFilterOptions,
      items: [],
      revisionRequests: [],
      filtersForm: {
        entityType: null,
        entityId: null,
        status: null,
        requestedBy: null,
        updatedBy: null,
        decidedBy: null,
      },
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 20,
      },
      loadedPages: [ ],
    };
  },
  methods: {
    formateRequestedBy(item) {
      if (item.requested_by) {
        return item.requested_by.fullname;
      }

      if (item.requested_by_acl_uuid) {
        return item.requested_by_acl_uuid.fullname;
      }

      return 'N/A';
    },
    formateUpdatedBy(item) {
      if (item.updated_by) {
        return item.updated_by.fullname;
      }

      if (item.updated_by_acl_uuid) {
        return item.updated_by_acl_uuid.fullname;
      }

      return 'N/A';
    },
    formateDecidedBy(item) {
      if (item.decided_by) {
        return item.decided_by.fullname;
      }

      if (item.decided_by_acl_uuid) {
        return item.decided_by_acl_uuid.fullname;
      }

      return 'N/A';
    },
    fetchRevisionRequests() {
      this.loading = true;

      const params = {
        limit: this.pagination.perPage,
        offset: (this.pagination.currentPage - 1) * this.pagination.perPage,
        sortOrder: 'desc',
        ...this.filtersForm,
      };

      if (this.filtersForm.status?.length) {
        params.status = this.filtersForm.status.join(',');
      }

      return axios
        .get('v1/revision-request', { params })
        .then(response => {
          this.pagination.totalRows = response.data.total;

          this.items = response.data.items.map(revisionRequest => ({
            id: revisionRequest.id,
            entity_type: revisionRequest.entity_type,
            entity_id: revisionRequest.entity_id,
            created_at: revisionRequest.created_at,
            requested_by: this.formateRequestedBy(revisionRequest),
            updated_at: revisionRequest.updated_at,
            updated_by: this.formateUpdatedBy(revisionRequest),
            status: revisionRequest.status,
            decided_by: this.formateDecidedBy(revisionRequest),
          }));
        })
        .catch(error => {
          console.error(error);
          this.$noty.error(`Error trying to fetch revision requests: ${error?.message}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchFilterOptions() {
      return axios
        .get('v1/revision-request/filter-options')
        .then(({ data: options }) => {
          this.entityTypesFilterOptions = [
            { text: 'Any type', value: null },
            ...options.entityTypes,
          ];
          this.userFilterOptions = [
            { text: 'Any user', value: null },
            ...options.users,
          ];
          this.statusFilterOptions = options.status;
        })
        .catch(error => {
          console.error(error);
          this.$noty.error(`Error fetching filter options: ${error?.message}`);
          const errorOptions = [
            { text: 'Error fetching options', value: null, disabled: true },
          ];
          this.entityTypesFilterOptions = errorOptions;
          this.userFilterOptions = errorOptions;
          this.statusFilterOptions = errorOptions;
        });
    },
    submitFilters(event) {
      event.preventDefault();
      return this.updatePage(1);
    },
    resetFilters(event) {
      event.preventDefault();
      Object
        .keys(this.filtersForm)
        .forEach(key => {
          this.filtersForm[key] = null;
        });

      return this.updatePage(1);
    },
    updatePage(newPage) {
      this.pagination.currentPage = newPage;
      return this.fetchRevisionRequests();
    },
  },
  beforeMount() {
    return Promise.all([
      this.fetchRevisionRequests(),
      this.fetchFilterOptions(),
    ]);
  },
};
</script>

<style scoped>
.b-pagination {
  .page-link {
    color: #007bff !important;
  }
  .page-item.active .page-link {
    background-color: #007bff !important;
    border-color: #007bff !important;
  }
}
</style>
